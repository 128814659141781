import { graphql } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import tw from "twin.macro"
import SEO from "../components/seo"
import Img from "gatsby-image"
import { BsChevronBarDown } from "react-icons/bs"

import makeCarousel from "react-reveal/makeCarousel"
import Fade from "react-reveal/Fade"
import styled from "@emotion/styled"
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl"

import FormatText from "../components/wysiwyg"
import AnchorLink from "react-anchor-link-smooth-scroll"

export const pageQuery = graphql`
  query HomeQuery($locale: String) {
    socials: allContentfulSocials {
      edges {
        node {
          title
          url
        }
      }
    }
    about: allContentfulAbout(
      filter: { slug: { eq: "inicio" }, node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          title
          aboutBio {
            json
          }
          id
        }
      }
    }
    slider: allContentfulSliders(
      filter: { slug: { eq: "inicio" }, node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          id
          images {
            id
            title
            fluid(maxWidth: 2000) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`

const CarouselUI = ({ children }) => <Container>{children}</Container>
const Carousel = makeCarousel(CarouselUI)

const IndexPage = ({ data  }) => {
  const intl = useIntl()
  return (
    <Layout>
      <SEO lang={intl.locale} title={intl.formatMessage({ id: "home" })}/>
      
      {data.slider.edges.map((slider, i) => (
        <Carousel
          defaultWait={3000}
          maxTurns={100} /*wait for 1000 milliseconds*/
        >
          {slider.node.images.map((item, i) => (
            <Fade key={item.id}>
              <Img
                title={`@Sol Etchegaray - ${item.title}`}
                fluid={item.fluid}
                alt={`@Sol Etchegaray - ${item.title}`}
              />
            </Fade>
          ))}
        </Carousel>
      ))}

      <div
        tw="relative z-50 max-w-4xl p-2 px-12 text-center mx-auto -mt-24 bg-black md:-mt-24"
        style={{ background: "rgba(0,0,0,.8)" }}
      >
        {data.about.edges.map(({ node }) => {
          return (
            <div id={node.id} tw="max-w-xl pt-6 mx-auto">
              <div className="flex">
                <h2 tw="pb-2 text-2xl italic text-left text-white mr-6">
                  {" "}
                  {node.title}
                </h2>
                <Fade delay={1300} bottom>
                  <AnchorLink
                    className="inline-block mt-1 text-3xl"
                    href="#bio"
                    title="ir a la biografía"
                  >
                    <BsChevronBarDown />
                  </AnchorLink>
                </Fade>
              </div>
              <div id="bio" tw="pt-16">
                <FormatText FormatText={node.aboutBio} />
              </div>
            </div>
          )
        })}
        <div tw="mt-12 max-w-md mx-auto">
          <Link
            tw="block  px-4 py-2 mt-2 mr-2 text-xl font-bold text-gray-800 hover:text-yellow-700 uppercase bg-white"
            to="/albums"
          >
            <FormattedMessage id="see" />{" "} 
            <FormattedMessage id="see_gallery" />
          </Link>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage

const Container = styled.div`
  ${tw`relative z-10 flex items-center justify-center w-full overflow-hidden text-base bg-black md:block `}
  height: 100vh;

  .gatsby-image-wrapper {
    ${tw`h-screen `}
  }

  img {
    ${tw`object-cover w-full h-screen md:h-auto`}
  }
`
